<script lang="ts" setup>
const {
  rating,
  max = 5,
  withText = false,
} = defineProps<{
  rating: number
  max?: number
  withText?: boolean
}>()

const roundedRating = Math.round(rating)
</script>

<template>
  <div class="inline-flex items-end">
    <UIcon
      v-for="i in max"
      :key="i"
      name="i-fas-star"
      :class="{
        'text-yellow-400': i <= roundedRating,
        'text-gray-300': i > roundedRating,
      }"
    />
    <p v-if="withText" class="ml-1 leading-none text-gray-500">
      {{ rating.toFixed(1) }}/{{ max.toFixed(1) }}
    </p>
  </div>
</template>
